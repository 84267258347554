/** @format */
import "./../businessCategory/businessCategory.scss";
import { useSearchParams, Link, useNavigate } from "react-router-dom"; // useParams, 
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { getRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { currentAddress } from '../../redux/reducers/address';
import { changeLoader } from "../../redux/reducers/loader";
import { changeActiveLink } from "../../redux/reducers/activeLink";
import { getNameBasedOnLanguage } from '../../Helpers/HelperFunctions';
import { axiosConstant } from '../../Helpers/Axios/Constants';
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';
import sec4Img from "../../assets/images/structure/sec4-img.webp";
import playStore from "../../assets/images/structure/play-store.svg";
import appleStore from "../../assets/images/structure/apple-store.svg";
import Footer from "../layout/footer/Footer";
import Breadcrumb from "../../shared/Breadcrumb";
import StoreDetails from "../businessCategory/storeDetails/StoreDetails";
import Image from "../../global-modules/Image/Image";
import { ampli } from "../../../src/ampli";
import jwt_decode from "jwt-decode";
import moengage from '@moengage/web-sdk';

const BusinessCategory1 = () => {
  const { t } = useTranslation();
  // const { storeslug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userAddress = useSelector(currentAddress)
  const snackbar = useSelector(currentSnackbar);
  const [searchParams] = useSearchParams();
  let [search, setSearch] = useState('');
  let [storeDetails, setStoreDetails] = useState(null);
  let [storeCategories, setStoreCategories] = useState(null);

  const setActiveLink = async (typeid) => {
    setLocalStorage(LOCAL_STORAGE_KEYS.ACTIVE_LINK, typeid)
    dispatch(changeActiveLink());
  }

  const handleSearch = (data) => {
    if (storeDetails) {
      search = data;
      setSearch(search)
      if (search !== '') {
        navigate(`/store/${storeDetails?.slug}?s=${search}`)
      }
    }
  }

  const getStoreDetails = async () => {
    dispatch(changeLoader(true));
    let url = API_ROUTES.STOREFRONT.GET_STORE_DETAILS('baladi-express-store');
    url += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    url += search !== '' ? `&search=${search}` : '';
    const response = await getRequest(url);
    const status = response.ack;
    if (status === 1) {
      let data = response.store;
      let offers = data.discounts_and_offer_relations?.map((offer) => offer?.discounts_and_offer)
      if (offers?.length === 0) {
        offers = response.discountDataOfAll.filter((offer) => (offer.apply_type === 'store' || offer.apply_type === 'business_type'))
      }
      if (offers?.length > 0) { offers = [] }
      data.offers = offers
      storeDetails = data;
      setStoreDetails(storeDetails);
      search = searchParams.get('s') ? searchParams.get('s') : '';
      setSearch(search);
      if (search !== '') {
        navigate(`/store/${storeDetails?.slug}?s=${search}`)
      }
      setActiveLink(data?.business_type?.slug)
      let allCategories = [];

      response?.categories.map((category) => {
        const allOffersCategory = response?.discountDataOfAll?.filter((offer) => offer.apply_type === 'category')
        let offers = allOffersCategory?.filter((offer) =>
          offer.apply_on === 'all' ||
          offer.discounts_and_offer_relations?.map(offe => offe.categoryId).includes(category.id)
        ) || [];
        if (offers?.length > 0) { offers = [] }
        category.offers = [...offers];
        return category
      })?.forEach(element => {
        if (element?.category?.length > 0) {
          let subCategories = [];
          element?.category.forEach(subElement => {
            if (subElement?.category.length > 0) {
              let subSubCategories = [];
              subElement?.category.forEach(subSubElement => {
                if (subSubElement?.productCount !== '0') {
                  subSubCategories?.push(subSubElement)
                }
              });
              if (subSubCategories?.length > 0) {
                subCategories.push({ ...subElement, category: subSubCategories })
              }
            } else {
              if (subElement?.productCount !== '0') {
                subCategories.push(subElement)
              }
            }
          });
          if (subCategories?.length > 0) {
            allCategories.push({ ...element, category: subCategories })
          }
        } else {
          if (element?.productCount !== '0') {
            allCategories?.push(element)
          }
        }
      });
      setStoreCategories(allCategories);
      dispatch(changeLoader(false));
      //ampli==
      let userID = '';
      if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
        const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
        let decoded = jwt_decode(token);
        userID = decoded?.id;
      }
      ampli.track({
        event_type: 'MenuCategoryViewed',
        event_properties: {
          category: 'Docs',
          name: 'SDK Library',
          description: 'SDK Library Description',
          user_id: userID ? userID : 0,
          store_id: storeDetails.stores_locales.find(locale => locale.locale === 'en').id,
          store_name: storeDetails.stores_locales.find(locale => locale.locale === 'en').business_name,
          category_id: storeDetails?.business_type.id ? storeDetails.business_type.id : 0,
          category_name: storeDetails.business_type.slug,
        },
      })
    } else {
      dispatch(changeLoader(false));
      setStoreDetails(null)
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
    }
  }

  useEffect(() => {
    search = searchParams.get('s') ? searchParams.get('s') : '';
    setSearch(search);
  }, [storeDetails]);

  useEffect(() => {
    getStoreDetails();
  }, [userAddress]);

  useEffect(() => {
    moengage?.track_event('Viewed Web Page');
  }, []);

  return (
    <div>
      <div className="business-category-wrapper">
        <div className="container-body">
          <Breadcrumb
            breadcrums={[
              {
                title: t("storefront_homepage"),
                haveLink: true,
                link: "/",
              },
              {
                title: t('storefront_AllCategories'),
                haveLink: false
              }
            ]}
          />
          <StoreDetails
            searchQuery={search}
            storeDetails={storeDetails}
            searchData={(searchdata) => {
              handleSearch(searchdata);
            }}
          />
          <div className="categories-wrapper">
            <div className="categories-right-main-wrap">
              <div className="right-head-box">
                <h4 className="h4">{t("categories")}</h4>
              </div>
              <div className="main-full-category">
                <Grid container justifyContent={'center'} columnSpacing={{ xs: 0, sm: 3, md: 2, xl: 2 }}>
                  {storeCategories?.length > 0 ? storeCategories.map((category, index) =>
                    <Grid item xs={6} sm={6} md={2} lg={2.2} xl={2} key={`category-${index}`}>
                      <Link to={`/store/${storeDetails?.slug}/${category?.slug}`} className="no-decoration">
                        <div className="main-full-category-box">
                          <div className="category-full-img-block" style={{
                            position: "relative",
                          }}>
                            <div className="chips-row">
                              {category?.offers?.filter((offerF, indexF) => indexF < 3).map((offer, index) =>
                                <span className="chips" key={`offerstoreDetails-${index}`} >{offer.name}</span>)}
                              {category?.offers?.length > 3 ? <span className="chips">+{category?.offers?.length - 3} {t("More")}</span> : null}
                            </div>

                            <Image
                              src={axiosConstant.IMAGE_BASE_URL(category?.image)}
                              className="category-full-img" alt={category?.image} />
                          </div>
                          <p className="h4 text-center">{getNameBasedOnLanguage(category?.category_locales)}</p>
                        </div>
                      </Link>
                    </Grid>) : null}
                </Grid>
              </div>
            </div>
            <div className="home-wrapper">
              <section className="home-sec4">
                <Grid
                  container
                  columnSpacing={{ xs: 0, sm: 3, md: 4, xl: 5 }}
                  style={{ position: "relative", zIndex: "1" }}
                >
                  <Grid item xs={12} sm={4} md={6} lg={6} xl={6}>
                    <img src={sec4Img} className="sec4img" alt="" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} pt={"45px"}>
                    <h2 className="h2">{t("makeOrdersWithOurApplication")}</h2>
                    <p className="p1">{t("getItNowAndGetSpecialOffers")}</p>
                    <Boxed
                      className="sec4-store-btns"
                      pl={"110px"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <img src={playStore} className="stores-img" alt="" />
                      <img src={appleStore} className="stores-img" alt="" />
                    </Boxed>
                  </Grid>
                </Grid>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BusinessCategory1;
