/** @format */
import * as React from "react";
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { axiosConstant } from '../../Helpers/Axios/Constants';
import Image from "../../global-modules/Image/Image";
import { useSelector } from "react-redux";
import { currentBaladiData } from "redux/reducers/baladiData";

const AddressBox = ({ cartStore }) => {
  const { t } = useTranslation();
  const baladiInfo = useSelector(currentBaladiData);

  return (
    <>
      {cartStore?.business_logo?<div className="store-detail-box">
        <h6 className="h6">{t("cart_StoreDetail")}</h6>
        <Boxed display={"flex"} alignItems={"center"}>
          <div className="logo-white-box">
            <Image
              src={axiosConstant.IMAGE_BASE_URL(cartStore?.business_logo)}
              className="str-logo" alt="store-logo" />
          </div>
          <div className="relative" style={{ margin: '0px 12px' }}>
            <p className="p2">{cartStore?.stores_locales[0].business_name}</p>
            {cartStore?.stores_locales[0]?.storeId !== baladiInfo.BALADI_STORE_Id?<p className="p3">
              {cartStore?.business_address && cartStore?.business_address !== "" ? cartStore?.business_address :
                `${cartStore?.street_number}, ${cartStore?.building_number},${cartStore?.zone_number}, ${cartStore?.country}`}
            </p>:<p className="p2 open-now">{t('storefront_Open24X7')} </p>}
          </div>
        </Boxed>
      </div>:null}
    </>
  );
};

export default AddressBox;