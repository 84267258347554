import "./OrderHistory.scss";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { getRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import { axiosConstant } from '../../Helpers/Axios/Constants';
import useIntersection from '../../Helpers/useIntersection'
import StatusBadge from '../../global-modules/StatusBadge/StatusBadge';
import { getLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';
import jwt_decode from "jwt-decode";
import NoDataFound from '../../global-modules/EmptyPages/NoDataFound'
import Sidebar from '../layout/sidebar/Sidebar';
import ClipLoader from "react-spinners/ClipLoader";
import SearchIcon from '@mui/icons-material/Search';
// import socket from '../../socket';
import Image from "../../global-modules/Image/Image";
import { ampli } from "../../ampli";
import { TextField } from "@mui/material";

const OrderHistory = () => {

  const ref = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const inViewport = useIntersection(ref, '0px');
  const [decodedUser, setDecodedUser] = useState(null);
  let [loadmore, setLoadmore] = useState(false);
  let [orderList, setOrderList] = useState([]);
  let [canLoad, setCanLoad] = useState(false);
  let [search, setSearch] = useState('');
  let [limit, setLimit] = useState(10);
  let [page, setPage] = useState(1);

  const getAllOrders = async () => {
    dispatch(changeLoader(true));
    limit = 10; setLimit(limit);
    page = 1; setPage(page)
    let url = API_ROUTES.ORDER.GET_ALL_ORDERS;
    url += `?limit=${limit}&page=${page}`;
    url += (search.trim() !== '') ? `&search=${search.trim()}` : '';
    const { ack, orders, msg } = await getRequest(url);
    if (ack === 1) {
      setOrderList(orders);
      dispatch(changeLoader(false));
      setTimeout(() => {
        setCanLoad(true)
      }, 1000);
    } else {
      setOrderList([])
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: msg,
          state: "error",
        })
      );
      dispatch(changeLoader(false));
    }
  }

  const loadMoreOrders = async () => {
    setCanLoad(false)
    setLoadmore(true)
    page++;
    setPage(page);
    let url = API_ROUTES.ORDER.GET_ALL_ORDERS;
    url += `?limit=${limit}&page=${page}`;
    url += (search.trim() !== '') ? `&search=${search.trim()}` : '';
    const { ack, orders } = await getRequest(url);
    if (ack === 1) {
      if (orders.length > 0) {
        orderList.push(...orders)
        setOrderList(orderList);
        setTimeout(() => {
          setCanLoad(true)
        }, 2000);
        setLoadmore(false)
      } else {
        setLoadmore(false)
        setCanLoad(false)
      }
    } else {
      setLoadmore(false)
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
    }
  }

  if (!loadmore && canLoad && inViewport) {
    loadMoreOrders();
  }

  useEffect(() => {
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const decoded = jwt_decode(getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
      setDecodedUser(decoded);
    }
  }, [])

  useEffect(() => {
    const getData = setTimeout(() => {
      getAllOrders()
    }, 1000);
    return () => clearTimeout(getData);
  }, [search])

  return (
    <div>
      <div className="main-wrapper full-bg">
        <Sidebar />
        <div className="main-mid-wrapper">
          <div className="order-history-wrapper">
            <div className="order-history-head" style={{
              marginBottom: '20px', display: 'flex', justifyContent: 'space-between'
            }}>
              <h3 className="h3" style={{ marginBottom: '0px' }}>{t('order_myorder')}</h3>
              <TextField
                id="outlined-basic" sx={{ width: '100%', backgroundColor: '#fff', }}
                className="order-search" InputProps={{
                  startAdornment: <SearchIcon />
                }}
                type="search"
                style={{ maxWidth: '400px' }} variant="outlined" value={search}
                placeholder={t('order_searchByOrderId')}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            {orderList.length > 0 ? orderList.map((order, index) =>
            <Link to={`/order-detail/${order?.id}`} key={`order-list-${index}`} className="no-decoration" style={{ display: 'block' }}>
              <div className='card-white'>
                <div className='order-history-list-wrap'>
                  <Boxed display={'flex'} alignItems={'center'} justifyContent={'space-between'}
                    className="history-top-block">
                    <Boxed display={'flex'} alignItems={'center'} className="history-left-block">
                      <div className="history-logo-block">
                        <Image
                          src={axiosConstant.IMAGE_BASE_URL(order?.store?.business_logo)}
                          className="history-logo-img" alt="" />
                      </div>
                      <div className="history-content-block">
                        <h6 className="h6">{order?.store?.stores_locales[0].business_name}</h6>
                        <p className="p3">#{order?.order_id}</p>
                        <p className="p2 text-truncate">
                          {order?.orders_details?.map((orders_detail, index) => {
                            return index === 0 ?
                              `${orders_detail?.product.products_locales[0].title} ${orders_detail?.uom?.uomName} x ${orders_detail?.quantity}` :
                              `, ${orders_detail?.product.products_locales[0].title} ${orders_detail?.uom?.uomName} x ${orders_detail?.quantity}`
                          })}
                        </p>
                      </div>
                    </Boxed>
                    <div className="history-right-block">
                      <StatusBadge status={order?.status} />
                    </div>
                  </Boxed>
                </div>
              </div>
            </Link>) : <NoDataFound text={t('order_no_order')} />}
            <div className='loader-wrap' ref={ref} style={{ marginBottom: '20px' }}>
              <ClipLoader color={"#EF4930"} loading={loadmore} speedMultiplier={0.5} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderHistory;
