// // Meta.js

// import React from 'react';
// import { Helmet } from 'react-helmet';

// const Meta = ({ metaData }) => {
//   return (
//     <Helmet>
//           <title>{metaData?.title}</title>
          
//       <meta name="facebook-domain-verification" content="qe4aq9ca69iqxy2e33tbdmkufkypuo" />
//       <meta name="title" content={metaData?.title} />
//       <meta name="description" content={metaData?.description} />
//       <meta property="og:type" content="website" />
//       <meta property="og:url" content={metaData?.url} />
//       <meta property="og:title" content={metaData?.title} />
//       <meta property="og:description" content={metaData?.description} />
//       <meta property="og:image" content={metaData?.image} />
//       <meta property="twitter:card" content="summary_large_image" />
//       <meta property="twitter:url" content={metaData?.url} />
//       <meta property="twitter:title" content={metaData?.title} />
//       <meta property="twitter:description" content={metaData?.description} />
//       <meta property="twitter:image" content={metaData?.image} />
//     </Helmet>
//   );
// };

// export default Meta;


// MetaTags.js
import React, { useEffect, useState } from 'react';
// import { Helmet } from 'react-helmet-async';
import MetaTags from 'react-meta-tags';

const MetaTagsx = () => {
  const [metaData, setMetaData] = useState({
  });

//   useEffect(() => {
//     // Fetch meta tag data from an API
//     fetch('/api/meta')
//       .then(response => response.json())
//       .then(data => {
//         setMetaData({
//           url: data.url,
//           title: data.title,
//           description: data.description,
//           image: data.image
//         });
//       })
//       .catch(error => console.error('Error fetching meta data:', error));
//   }, []);
useEffect(() => {
    async function fetchProductTitle(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
          console.log('Product Title:', data.title); // Log the title from JSON response
          
        setMetaData({
          title: data.title || 'Baladi Express',
          description: data.description || 'Default Description',
          url: data.url || 'https://baladiexpress.com',
          image: data.image || 'http://baladiexpress.com/Balad_logo.png'
        });
        
        // setMetaDatashow(true);
      } catch (error) {
        console.error('Fetch error:', error);
      }
    }

    function checkUrlAndFetch() {
      const currentUrl = window.location.href;
      if (currentUrl?.includes('product-detail')) {
        const parts = currentUrl.split('/');
        const slug = parts[parts.length - 1];
        const url = `https://api.baladiexpress.com/v7/products/get_product_title?slug=${slug}&&url=${currentUrl}`;
        fetchProductTitle(url);
      } else {
        const url = `https://api.baladiexpress.com/v7/products/get_product_title?url=${currentUrl}`;
        fetchProductTitle(url);
        console.error('URL does not contain product-detail');
      }
    }

    checkUrlAndFetch();

    const popstateHandler = () => {
      checkUrlAndFetch();
    };

    window.addEventListener('popstate', popstateHandler);

    const clickHandler = (event) => {
      const target = event.target.closest('a');
      if (target) {
        setTimeout(checkUrlAndFetch, 100);
      }
    };

    document.addEventListener('click', clickHandler);

    return () => {
      window.removeEventListener('popstate', popstateHandler);
      document.removeEventListener('click', clickHandler);
    };
  }, []);
  return (
    <MetaTags>
            <title>{metaData?.title}</title>
          
            {/* <meta name="facebook-domain-verification" content="qe4aq9ca69iqxy2e33tbdmkufkypuo" /> */}
            <meta name="title" content={metaData?.title} />
            <meta name="description" content={metaData?.description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={metaData?.url} />
            <meta property="og:title" content={metaData?.title} /> 
             <meta property="og:description" content={metaData?.description} />
            <meta property="og:image" content={metaData?.image} />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={metaData?.url} />
            <meta property="twitter:title" content={metaData?.title} />
            <meta property="twitter:description" content={metaData?.description} />
           <meta property="twitter:image" content={metaData?.image} />
    </MetaTags>
  );
};

export default MetaTagsx;
